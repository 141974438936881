import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";

import MainBanner from "@components/goautowarranty/mainBanner";
import GoAutoWarrantyWrapper from "@components/layouts/goAutoWarrantyWrapper";
import { useRouter } from "next/router";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const Benefits = dynamic(() => import("@components/goautowarranty/benefits"));
const FinalSection = dynamic(
    () => import("@components/goautowarranty/finalSection"),
);
const Features = dynamic(() => import("@components/goautowarranty/features"));
const AutoWarrantyMakers = dynamic(
    () => import("@components/shared/autoWarrantyMakers"),
);
export default function GoAutoWarrantyHome(): ReactElement {
    const { domain } = useDomainContext();
    const {
        query: { hc: hideContent, ha: hideAll },
    } = useRouter();
    return (
        <GoAutoWarrantyWrapper>
            <MainBanner />
            {!hideAll && !hideContent && <Benefits />}
            {!hideAll && (
                <section className="mb-16">
                    <OffersList offers={domain?.defaultOffers} />
                </section>
            )}
            {!hideAll && !hideContent && (
                <>
                    <Features />
                    <FinalSection />
                    <AutoWarrantyMakers />
                </>
            )}
        </GoAutoWarrantyWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
